import {
	primaryColor,
	warningColor,
	dangerColor,
	successColor,
	infoColor,
	roseColor,
	grayColor,
	title,
} from 'global-material';

export const infoStyle = {
	infoArea: {
		maxWidth: '360px',
		margin: '0 auto',
		padding: '0px',
	},
	iconWrapper: {
		float: 'left',
		marginTop: '24px',
		marginRight: '10px',
	},
	primary: {
		color: primaryColor,
	},
	warning: {
		color: warningColor,
	},
	danger: {
		color: dangerColor,
	},
	success: {
		color: successColor,
	},
	info: {
		color: infoColor,
	},
	rose: {
		color: roseColor,
	},
	gray: {
		color: grayColor,
	},
	icon: {
		width: '36px',
		height: '36px',
	},
	descriptionWrapper: {
		color: grayColor,
		overflow: 'hidden',
	},
	title: {
		...title,
		margin: '0.875rem 0',
	},
	rotate: {
		transform: 'rotate(180deg)',
	},
	description: {
		color: grayColor,
		overflow: 'hidden',
		marginTop: '0px',
		fontSize: '14px',
	},
	iconWrapperVertical: {
		float: 'none',
	},
	iconVertical: {
		width: '61px',
		height: '61px',
	},
};

export default infoStyle;
