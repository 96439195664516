/* eslint-disable quotes */
import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// @material-ui/icons
import { Home, DirectionsCar, Business, More } from '@material-ui/icons';
import { Link } from 'gatsby';

// core components
import { GridContainer, GridItem } from 'components/Grid/index';
import InfoArea from 'components/InfoArea/InfoArea';

import { productStyle } from './Sections.css';

const ProductSection = ({ classes }) => (
	<div className={classes.section}>
		<GridContainer justify='center'>
			<GridItem xs={12} sm={12} md={8}>
				<h2 className={classes.title}>{"Let's talk products"}</h2>
				<h5 className={classes.description}>
					{`ARC Insurance Agency has a market, product, and competitive price for any of the insurance needs of our clients. We work with multiple carriers that cater to many different clients and coverage niches. Our agents work with our carrier underwriters and sales representatives to find the best outcomes for our clients' coverage and premium needs. No matter what your situation is, we can find the right coverage for you with a fantastic, service oriented, agent approach; and, at an amazing price.`}
				</h5>
			</GridItem>
		</GridContainer>
		<div>
			<GridContainer>
				<GridItem xs={12} sm={6} md={6}>
					<Link key='home' to='/services/home-insurance'>
						<InfoArea
							title='Home Insurance'
							description='Whether you are buying a new home, fixing up an older home, or renting an apartment,'
							icon={Home}
							iconColor='info'
							vertical
						/>
					</Link>
				</GridItem>
				<GridItem xs={12} sm={6} md={6}>
					<Link key='auto' to='/services/auto-insurance'>
						<InfoArea
							title='Auto Insurance'
							description='Whether you are a perfect driver or have hit a few speed bumps along the road, we have the perfect coverage for you.'
							icon={DirectionsCar}
							iconColor='success'
							vertical
						/>
					</Link>
				</GridItem>
				<GridItem xs={12} sm={6} md={6}>
					<Link key='business' to='/services/business-insurance'>
						<InfoArea
							title='Business Insurance'
							description='Whether you have a small side business or are currently running a large business with multiple employees and processes.'
							icon={Business}
							iconColor='danger'
							vertical
						/>
					</Link>
				</GridItem>
				<GridItem xs={12} sm={6} md={6}>
					<Link key='other' to='/services/other-insurance'>
						<InfoArea
							title='Other Insurance'
							description='Whether you need to insure a boat or watercraft, jewelry or other high value items, or just want to insure your next trip.'
							icon={More}
							iconColor='primary'
							vertical
							rotate
						/>
					</Link>
				</GridItem>
			</GridContainer>
		</div>
	</div>
);

ProductSection.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(productStyle)(ProductSection);
