import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import 'assets/scss/material-ui.scss?v=1.4.0';

import Layout from 'components/layout';
import ProductSection from 'components/Sections/ProductSection';
import Parallax from 'components/Parallax';
import Footer from 'components/Footer/Footer';
import { GridContainer, GridItem as BaseGridItem } from 'components/Grid';
import { landingPageStyles } from 'styles/index.css';

const GridItem = styled(BaseGridItem)`
	border-radius: 10px;
	backdrop-filter: blur(1px);
`;

const SpecialContainer = styled.div`
	@media only screen and (max-width: 667px) {
		margin-top: 4rem;
		h4 {
			max-height: 6rem;
			overflow-y: auto;
		}
	}
	@media only screen and (max-width: 400px) {
		h4 {
			max-height: 8rem;
			overflow-y: auto;
		}
	}
`;

const Index = ({
	classes,
	data: {
		homeJson: { title: pageTitle },
	},
}) => (
	<Layout pageTitle={pageTitle}>
		<Parallax filter image={require('assets/img/richmond-bg.png')}>
			<SpecialContainer className={classes.container}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={10}>
						<h1 className={classes.title}>
							Your Story Starts With Us.
						</h1>
						<h4 style={{ fontWeight: 500 }}>
							ARC Insurance Agency is an independent insurance
							agency, and we are proud to call Richmond, Virginia
							our home. We primarily focus on Richmond City,
							Henrico, and Chesterfield counties, but are excited
							to provide service statewide. Our status as an
							independent insurance agency allows our producers to
							gain access to multiple insurance carriers and offer
							the insurance products that best fit your needs.
							Whether you are buying a new home or car, starting a
							new business, or simply looking for better rates and
							service on your current insurance policies, ARC
							Insurance Agency can provide you with great coverage
							and a highly competitive market price.
						</h4>
					</GridItem>
				</GridContainer>
			</SpecialContainer>
		</Parallax>
		<div className={classNames(classes.main, classes.mainRaised)}>
			<div className={classes.container}>
				<ProductSection />
			</div>
		</div>
		<Footer />
	</Layout>
);

Index.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
};

export default withStyles(landingPageStyles)(Index);

export const query = graphql`
	query HomepageQuery {
		homeJson {
			title
		}
	}
`;
